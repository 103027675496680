import React from 'react';
import Pill from '@ingka/pill';
import crossIcon from '@ingka/ssr-icon/paths/cross-small';
import Text from '@ingka/text';
import { FilterContextInputsKeyWithoutPage, useFilter } from '../../../hooks/contexts/FilterContext';
import { filterPillOptions } from './filterOptions';
import { SortInput } from '__generated__/types';
import { getSortFieldLabel } from './fieldlabels';
import * as Styled from './styled';

type ActiveFiltersRowProps = {
  sort: SortInput;
  defaultSort: SortInput;
  deselectFilter: (key: FilterContextInputsKeyWithoutPage) => void;
  clearFilter: () => void;
  clearSort: () => void;
};

export const ActiveFiltersRow: React.FC<ActiveFiltersRowProps> = ({ sort, defaultSort, deselectFilter, clearFilter, clearSort }) => {
  const {
    filter: { optimized, selfCanonicalized, hasProducts, homeFurnishingBusinessId, needsReview, valid, targetStatus, invocations, catalogName },
  } = useFilter();
  const filterOptionsForLabel = {
    optimized,
    selfCanonicalized,
    hasProducts,
    homeFurnishingBusinessId,
    needsReview,
    catalogName,
    valid,
    targetStatus,
    invocations,
  };
  const isFiltersVisible = Object.entries(filterOptionsForLabel).some(([key, value]) => value || value === false);
  const isSortVisible = !(sort.field === defaultSort.field && sort.direction === defaultSort.direction);
  if (!isFiltersVisible && !isSortVisible) return null;
  return (
    <Styled.ActiveFiltersRowContainer gap={0.3} align="center" data-cy={'active-filters-row'}>
      <Text>Active filter:</Text>
      {isFiltersVisible && (
        <>
          {Object.entries(filterOptionsForLabel).map(([key, value]) => {
            if (!value && value !== false) return null;
            const filterKey = filterPillOptions[key]?.field as FilterContextInputsKeyWithoutPage;
            return (
              <Pill
                key={filterKey as string}
                ssrIcon={crossIcon}
                onClick={() => deselectFilter(filterKey)}
                label={filterPillOptions[key]?.label({ [filterKey as string]: value })}
                size="small"
              />
            );
          })}
        </>
      )}
      {isSortVisible && sort.field && (
        <Pill ssrIcon={crossIcon} onClick={clearSort} label={getSortFieldLabel(`${sort.field},${sort.direction}`)} size="small" />
      )}
      <Styled.ClearAllFilters
        onClick={() => {
          clearFilter();
          clearSort();
        }}
        tagName="p"
      >
        Clear all
      </Styled.ClearAllFilters>
    </Styled.ActiveFiltersRowContainer>
  );
};
