import styled from '@emotion/styled';
import { colourNeutral3, colourStaticBlack, colourNeutral1 } from '@ingka/variables/colours-css';
import { fontSize50, space125, space75, thicknessThin } from '@ingka/variables';

export const AutocompleteContainer = styled.div`
  width: 100%;
  position: relative;
  input {
    font-size: ${fontSize50};
  }
`;

export const OptionsList = styled.ul`
  list-style: none;
  margin-top: -${space125};
  padding: 0;
  width: 100%;
  max-height: 300px;
  background-color: ${colourNeutral1};
  overflow-y: auto;
  border: ${thicknessThin} solid ${colourStaticBlack};
  border-top: none;
  position: absolute;
  z-index: 100;
`;

export const OptionItem = styled.li`
  padding: ${space75};
  cursor: pointer;
  font-size: ${fontSize50};
  &:hover {
    background-color: ${colourNeutral3};
  }
`;
