import React from 'react';
import Checkbox from '@ingka/checkbox';
import RadioButtonGroup from '@ingka/radio-button-group';
import { SortInput } from '__generated__/types';
import { HFBDropdown } from './HFBDropdown';
import { SortDropdown } from './SortDropdown';
import * as Styled from './styled';
import { FilterContextInputs } from '../../../hooks/contexts/FilterContext';
import { getMetadataFilterOptions, getRedirectFilterOptions } from './filterOptions';
import { PageWithControlName } from '../index';
import { OnFilterChange } from './FilterAndSortModal';
import { Autocomplete } from '../../Autocomplete';
import { CatalogReference } from '../../../types';
import { FlexContainer } from '../../FlexContainer';
import { Features, useFeatureContext } from '../../../hooks/contexts/FeatureContext';

type ValidityFilterProps = {
  type: string;
  sort?: SortInput;
  onFilterChange: OnFilterChange;
  onSortChange: (sort: SortInput) => void;
  pageName: PageWithControlName;
  filter?: FilterContextInputs;
  categories?: CatalogReference[] | null;
};

export const ValidityFilter: React.FC<ValidityFilterProps> = ({ type, sort, onFilterChange, onSortChange, pageName, filter, categories }) => {
  const { validArray, selfCanonicalArray, hasProductsArray, optimizedArray } = getMetadataFilterOptions(onFilterChange, { filter, type });
  const { targetStatusArray, usageArray } = getRedirectFilterOptions(onFilterChange, { filter });
  const { isFeatureDisabled } = useFeatureContext();
  const isPipCategoriesFilterDisabled = isFeatureDisabled(Features.PIP_CATEGORIES_FILTER);
  const rulesRadioOptions = [
    { name: 'Target Status', list: targetStatusArray },
    {
      name: 'Usage',
      list: usageArray,
    },
  ];
  const columnSize = type === 'PLP' ? 4 : pageName === 'rules' ? 2 : 3;
  return (
    <Styled.ValidityFilterWrapper columns={columnSize}>
      {pageName === 'metadata' && (
        <>
          <FlexContainer direction="column">
            <RadioButtonGroup name={type !== 'filter' ? 'Optimized' : 'Published'} list={optimizedArray} />
            <Styled.ValidityFilterCheckboxWrapper>
              <Checkbox
                name={'needsReview=filter'}
                key={'needsReview=filter'}
                id={'needsReview=filter'}
                checked={!!filter?.needsReview}
                label={'Needs Review'}
                value={'needsReview'}
                onChange={() => onFilterChange('needsReview', filter?.needsReview ? null : true)}
              />
              {type === 'PIP' && (
                <Styled.ValidityFilterDropdown gap={0.5} justify={'space-evenly'}>
                  <HFBDropdown
                    value={filter?.homeFurnishingBusinessId ?? ''}
                    onChange={({ value }) => onFilterChange('homeFurnishingBusinessId', value)}
                  />
                  {categories && isPipCategoriesFilterDisabled && (
                    <Autocomplete
                      options={categories}
                      value={filter?.catalogName ?? 'All Categories'}
                      onOptionSelect={(value) => onFilterChange('catalogName', value.name)}
                    />
                  )}
                </Styled.ValidityFilterDropdown>
              )}

              {type === 'PLP' && sort && (
                <Styled.ValidityFilterDropdown>
                  <SortDropdown sort={sort} onSortChange={onSortChange} />
                </Styled.ValidityFilterDropdown>
              )}
            </Styled.ValidityFilterCheckboxWrapper>
          </FlexContainer>
          <RadioButtonGroup name={'Valid'} list={validArray} />
          <RadioButtonGroup name={'Self Canonicalized'} list={selfCanonicalArray} />
          {type === 'PLP' && <RadioButtonGroup name={'Has Products'} list={hasProductsArray} />}
        </>
      )}
      {pageName === 'rules' && rulesRadioOptions.map(({ name, list }) => <RadioButtonGroup name={name} list={list} key={name} />)}
    </Styled.ValidityFilterWrapper>
  );
};
